<script setup lang="ts">
import { ref, computed } from 'vue'
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'

const props = withDefaults(
  defineProps<{
    info: Record<string, unknown>
    heroImage: Record<string, unknown>
    bannerAction: {
      type?: string
    }
    backgroundColor?: string | null
    textColor?: string | null
    side?: string | null
  }>(),
  {
    info: () => ({}),
    heroImage: () => ({}),
    bannerAction: () => ({}),
    backgroundColor: null,
    textColor: null,
    side: null,
  }
)

const banner = ref<HTMLDivElement | null>(null)
const bannerStyle = computed(() => ({
  color: props.textColor,
  textAlign: props.info.contentAlignmentX,
  backgroundColor: props.backgroundColor,
}))
</script>

<template>
  <section>
    <base-action
      v-bind="bannerAction"
      class="block hover:opacity-90"
      origin="banner"
      appearance="link"
    >
      <div
        ref="banner"
        class="flex w-auto flex-col items-center overflow-hidden rounded-md border md:max-w-[300px]"
        :class="{ 'bg-contain': banner && banner.clientHeight > 300 }"
        :style="bannerStyle"
      >
        <img
          :src="
            cdn(heroImage.desktop)
              .preview('1706x856')
              .format('auto')
              .quality('smart')
              .url()
          "
          :alt="heroImage.alt"
          class="w-full"
        />
        <div class="flex h-14 items-center px-2.5">
          <div
            v-if="info.title"
            class="block text-center text-sm font-semibold"
            v-html="info.title"
          />
        </div>
      </div>
    </base-action>
  </section>
</template>
